<template>
  <div class="container xl">
    <div class="main-cols">
      <div class="left-menu-col">
        <LeftMenu />
      </div>
      <div class="right-content-col">
        <AddressList />
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from '@/components/LeftMenu';
import AddressList from '@/components/AddressList';

export default {
  name: 'AddressesView',
  components: {
    LeftMenu,
    AddressList
  },
};
</script>
